import './App.css';

function App() {
  return (
    <div id="page-top">
      <div id="apDiv2">
        <a href="#myModal">
           
          </a>
      </div>

      <div id="apDiv4">
        <div className="apDiv4-call" data-toggle="modal" data-target="#myModals">
          <i className="fa fa-car faa-tada animated"></i>&ensp;&ensp;Site Visit
        </div>
      </div>
      <div id="apDiv5">
        <div className="apDiv5-call"><a href="tel:+919130568016">
          <i className="fa fa-phone faa-tada animated"></i>&ensp;+91 9130568016</a>
        </div>
      </div>


      <div id="myModal" className="modal fade" role="dialog">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title pop_align">Today Global Aamod Panvel Brochure</h4>
               </div>
               <div className="modal-body">
                  <form action="maildownload.php" method="post">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnames" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emails" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobiles" placeholder="Mobile" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgs" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" name="save" className="btn btn-sl-form"  value="Submit" />
                        </div>
                     </div>
                     <div className="success_message1 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>
	  
	  
		<div id="ganesh" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Site Visit Pickup & Drop</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="Panvel">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fname1" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobile1" placeholder="Mobile *" required />
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-calendar "></i></span>
								<input type="date" className="form-control" name="date" id="date1" placeholder="Date *" required />
				   
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-map-marker"></i></span>
								<textarea className="form-control" name="location" id="location2" placeholder="Location *" required></textarea>
							</div>
						</div>
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_offerspop pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>


      <div id="myModals" className="modal fade">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                  <h4 className="modal-title pop_align">Get in touch for Today Global Aamod Panvel </h4>
               </div>
               <div className="modal-body">
                  <form  method="post" id="signupForm2">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnameb" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emailb" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group">
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <div className="col-lg-3">
                              <div className="row">
                                 <select name="code" id="codes" className="form-control" >
                                    <option  value="">Select Country</option>
                                    <option value="+91">India</option>
                                    <option value="+971">United Arab Emirates</option>
                                    <option value="+1">USA</option>
                                    <option value="+1">Canada</option>
                                    <option value="+44">United Kingdom</option>
                                    <option value="+61">Australia</option>
                                    <option value="+65">Singapore</option>
                                    <option value="+974">Qatar</option>
                                    <option value="+968">Oman</option>
                                    <option value="+64">New Zealand</option>
                                    <option value="+60">Malaysia</option>
                                    <option value="+852">Hong Kong</option>
                                    <option value="+0">Others</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-9">
                              <div className="row">
                                 <input type="text" className="form-control" name="mobile" id="mobileb" placeholder="Mobile" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgb" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit" />
                        </div>
                     </div>
                     <div className="success_message2 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div id="feedback">
         <div id="feedback-form" className="col-xs-4 col-md-4 panel panel-default displaynone">
            <h3>Instant Call Back</h3>
            <form  method="post" id="signupForm3">
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-user"></i></span>
                     <input className="form-control" name="fname" id="fnamec" placeholder="Name" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                     <input type="email" className="form-control" name="email" id="emailc" placeholder="Email" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                     <input type="text" className="form-control" name="mobile" id="mobilec" placeholder="Mobile" />
                  </div>
               </div>
               <div className="pop_align">
                  <div className="form-grop">
                     <input type="submit" className="btn btn-sl-form" value="Submit"/>
                  </div>
               </div>
               <div className="success_message3 float_left"></div>
            </form>
         </div>
         <div id="feedback-tab">Instant Call Back</div>
      </div>
    
      <nav id="mainNav" className="navbar navbar-default navbar-fixed-top" >
         <div className="container">
            <div className="navbar-header">
	 
               <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
               <span className="sr-only">Toggle navigation</span> Menu <i className="fa fa-bars"></i>
               </button>
			    
               <a className="navbar-brand page-scroll" href="#page-top">
               <img src="img/todayaamod.jpg" width="113" className="img-responsive " alt="Today Global Aamod" title="Today Global Aamod" />
               </a>
            </div>
            <div className="cell">
               <a href="tel:+919130568016">
                  <div className="col-md-2 cell_phone"><i className="fa fa-phone"></i>&ensp;+91 91305 68016</div>
               </a>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
               <ul className="nav navbar-nav navbar-right">
                  <li><a className="page-scroll" href="#page-top">Home</a></li>
                  <li><a className="page-scroll" href="#about">About</a></li>
				  <li><a className="page-scroll" href="#rate">Configuration</a></li>
                  <li><a className="page-scroll" href="#amenities">Amenities</a></li>
                  <li><a className="page-scroll" href="#plans">Plan</a></li>
                  <li><a className="page-scroll" href="#location">Location</a></li>
                  <li><a className="page-scroll" href="#contact">Contact</a></li>
				  <li><a className="page-scroll" href="#" data-toggle="modal" data-target="#myModal"><b><i className="fa fa-download animated"></i> E-BROCHURE</b></a></li>
               </ul>
            </div>
         </div>
      </nav>
    
 
 
		 <header className="container-fluid md-3form">
	     <div className="row">
	     
			<div className="col-md-9 col-md-91">
				<div className="row">
		
		 
 
				 <div id="myCarousel" className="carousel slide " data-ride="carousel">
					 <ol className="carousel-indicators">
						<li data-target="#myCarousel" data-slide-to="0" className="active"></li>
						<li data-target="#myCarousel" data-slide-to="1"></li>
						<li data-target="#myCarousel" data-slide-to="2"></li>
						 
					  </ol>
						<div className="carousel-inner">
							<div className="item active">
								<img className="d-banner1" style={{width:"100%"}} src="img/aamodpanvel1.jpg" alt="Los Angeles"/>
							</div>
							<div className="item">
								<img className="d-banner1" style={{width:"100%"}} src="img/aamodpanvel2.jpg" alt="Los Angeles"/>
							</div>
							<div className="item">
								<img className="d-banner1" style={{width:"100%"}} src="img/aamodpanvel3.jpg" alt="Los Angeles"/>
							</div>
						</div>
						
						
					
					<a className="left carousel-control" href="#myCarousel" data-slide="prev">
					<i className="fa fa-arrow-left slicon-left"></i><span className="sr-only">Previous</span></a>
					<a className="right carousel-control" href="#myCarousel" data-slide="next"><i className="fa fa-arrow-right slicon-right"></i><span className="sr-only">Next</span></a>
					</div>
					</div>
					
	<div id="hero-content" className="text-align-center">
			<p className="top-patch">Booking Open</p>
			<div className="project-info">
				<p className="project-name">Today Global Aamod</p>
				<p className="project-extraname">Today Global Aamod</p>
				<p className="project-location">At Panvel</p>
				<p className="project-developer">By Today Global Developers</p>
			</div>
			<div className="project-addon-info">
				<p>→ Land Parcel: <b>5 Acres</b></p>
				<p>→ Possession: <b>June 2024</b></p>
				<p>→ Near <b>Palaspe Phata</b></p>
			</div>
			<div className="project-booking-info">
				<div className="project-booking-info-points" >EOI Amount<p></p></div>
				<p className="project-booking-amount">1 BHK - 54,000/- (Bankable)<br/>2 BHK - 1,08,000/- (Bankable)</p>
 
			</div>
			<div className="project-configuration-details">
				<p>Spacious Homes 1 & 2 BHK Starting </p>
			</div>
			<div className="project-price">
				<p>39 Lacs + Taxes</p>
			</div>
			<div className="project-enquiry">
			   <a href="#" data-toggle="modal" className="btn btn-price" data-target="#myModals"><b><i class="fa fa-car faa-tada animated"></i> Book Site Visit</b></a>
			</div>
			<div className="project-rera">
				<p>RERA No: P52000030717 </p>
			</div>
	   </div>
					
					
				</div> 

				<div className="col-md-3 col-md-31 sl">
				<div className="row">
				  <div className="sliderform1">
				 				 
				 <h4>First 50 EOI Clients would get Pre Launch Benefits</h4>
                     <p className="high_label ">
					 <span className="animated bounceIn infinite high_label_span">PRE-REGISTRATION OFFERS <br/><b>EOI - 54,000/- </b> </span></p>
					 
					 
                     <form method="post" id="sliderform">
                        <div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-user"></i></span>
                              <input className="form-control" name="fname" id="fnamef" placeholder="Name" required />
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                              <input type="email" className="form-control" name="email" id="emailf" placeholder="Email" required />
                           </div>
                        </div>
						<div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                              <input type="text"  className="form-control" name="mobile" id="mobilef" placeholder="Mobile" />
                           </div>
                        </div>
                       
                      
                           <div className="form-group pop_align">
                              <input type="submit" className="btn btn-sl-form" value="Submit" />
                           </div>
                       
                        <div className="success_message4 pop_align"></div>
                     </form> 
		
 
					<div className="form-title">
						<ul>
						    <li>1 & 2 BHK homes at Today Global Aamod Panvel Opportunities near birla College </li>
							<li>TODAY AAMOD is located at most of the Sensational Infrastructure in MMR.</li>
							<li>Navi Mumbai's very own international airport  has already begun the operations and is set to function by 2023. </li>
							<li>The new airport en route will enjoy elevated corridor for its connectivity to CSMT.</li>
							 			
						</ul>		
													
					</div>
					
				  </div>
				
				</div> 
				</div> 
	   </div>
    </header>
	
      <section className="contact_div form_mob">
         <div className="container ">
            <div className="row">
               <div className="col-md-8 col-md-offset-2 col-sm-9 col-sm-offset-1 text-center" >
                  <h4 className="form_mob_title">Pre-Register here for Best Offers</h4>
				   
                  
                   <form method="post" id="formmobile">
                <div className="form-group">
                    <input type="text" className="form-control form_mob_field" name="fname" id="fnamemid" placeholder="Name" required />
                </div>
                <div className="form-group">
                    <input type="email" className="form-control form_mob_field" name="email" id="emailmid" placeholder="Email" required />
                </div>
                <div className="form-group">

                    <input type="text" className="form-control form_mob_field" name="mobile" id="mobilemid" placeholder="Mobile" required />
                </div>
 
				<div className="col-md-3 col-md-offset-4">
					<div className="form-group">
						<input type="submit" className="btn btn-sl-form"  value="Submit" />
					</div>
				</div>
				<div className="success_mobile pop_align"></div>
			</form>
                  
               </div>
            </div>
         </div>
      </section>
			
	
      <section className="bg-primary1" id="about">
         <div className="container">
            <div className="row">
                
                  <h1 className="section-heading sectionheading1 text-center">Today Global Aamod</h1>
                  <hr className="light1"/> 
					<div className="col-lg-8 text-center">
				 		<p><b>Pre-Launching New Project Today Global Aamod at Panvel. </b></p>
						
						<p>Navi Mumbai has come a long way over the years and is known to turn every dream into reality. There is a lot to this city other than what meets the eye and that is the reason why Navi Mumbai is one of the most loved locations. With a number of people stepping into Navi Mumbai to turn their dreams into reality, its also a popular hotspot for investors as the property prices are always on a constant rise.<a href="https://todayaamod.pre-launch.co/" class="url">Today Global AAMOD</a> is a new Residential project at Panvel, Navi Mumbai which offers exclusive 1BHK & 2BHK residences. Its an emerging location with residential project in Panvel. It is the most premium area of Panvel near Malls, School and Hospitals. Its location is very close to Panvel station and major transit routes & proposed corporate campuses. </p>
						
						<p>Like all projects of Today Global Developers we emphasize on elements of Height, Light and Air. Every Space is built to maximise Cross Ventilation, Natural light and higher floor to floor heights.</p>
						
						<p>The external amenities at this project include Swimming Pool, Zen Yoga & Meditation Center, Multipurpose Hall, Gymnasium, Toddler play area, Senior Citizen Corner, Jogging Track etc.</p>
						
						<div className="form-group"><input type="submit" data-toggle="modal" data-target="#ganesh" className="btn btn-sl-form" value="Pick Up & Drop Facility" /></div>
						 
					</div>
				  
					  <div className="col-lg-4 text-center">
						<img src="img/todayaamod.jpeg" className="aboutimg img-responsive" alt="today aamod panvel"  title="today aamod panvel"/>
					  </div>
            </div>
         </div>
      </section>
    
	  <section id="rate" className="rate">
         <div className="container rate-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading-rate sectionheading2">Configuration</h2>
                  <hr className="color"/>
               </div>
            </div>
            <div className="col-lg-12 col-sm-12">
               <div className="row">
                  <table className="table table_pr" >
					<thead >
						<tr>
							<th className="table_th">Configuration</th>
							<th className="table_th">Carpet Area</th>
							<th className="table_th">Price</th>
						</tr>
					</thead>
					<tbody className="table_tbody">
						<tr>
							<td className="bg-grey-table1">1 BHK</td>
							<td className="bg-grey-table1">400 to 483 sq.ft.</td>
							<td className="bg-grey-table2">
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Get Price</a>
							</td>
						</tr>
						 
						<tr>
							<td className="bg-grey-table1">2 BHK</td>
							<td className="bg-grey-table1">550 to 691 sq.ft.</td>
							<td className="bg-grey-table2">
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Get Price</a>
							</td>
						</tr>

						<tr>
							<td className="bg-grey-table1 note-size" colspan="3">PMAY : Upto 2.67 Lacs Benefits | First 50 EOI clients would get Pre Launch benefits
</td>
						</tr>
						<tr>
							<td className="bg-grey-table2" colspan="3"><a href="#" data-toggle="modal" className="btn btn-price" data-target="#myModals">Enquire Now</a></td>
						</tr>

					</tbody>
				</table>  
               </div>
               <h3 className="pop_align1">For booking process please call us now: +91 9130568016</h3>
            </div>
			
		 
         </div>
      </section>
    
	  
      <section id="amenities">
         <div className="container amenities-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Amenities</h2>
                  <hr className="primary"/>
               </div>
            </div>
			
			
			<div className="row1">
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/clubhouse.png" alt="" height="75"/>
								  <p> Club House </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/running.png" alt="" height="75"/>
								  <p> Jogging Track</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/dumble.png" alt="" height="75"/>
								  <p> Gymnasium </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/squash.png" alt="" height="75"/>
								  <p> Squash Court </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/pool.png" alt="" height="75"/>
								  <p> Swimming Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/hall.png" alt="" height="75"/>
								  <p> Multipurpose Hall</p>
							</div>
						</div>

						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cafe.png" alt="" height="75"/>
								  <p> Cafe</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/gamepad.png" alt="" height="75"/>
								  <p> Indoor Game Room</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/medtation.png" alt="" height="75"/>
								  <p>Yoga Lawn</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/reflexology.png" alt="" height="75"/>
								  <p> Reflexology Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/kids-pay.png" alt="" height="75"/>
								  <p> Kid's Play Area</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/senior.png" alt="" height="75"/>
								  <p> Senior Citizen's Area</p>
							</div>
						</div>
						
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/ami.png" alt="" height="75"/>
								  <p> Amphith eatre</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/lib.png" alt="" height="75"/>
								  <p> E-Library</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/park.png" alt="" height="75"/>
								  <p> Central Greens</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cctv.png" alt="" height="75"/>
								  <p> Security</p>
							</div>
						</div>
						 
					</div>  
 
	   
         </div>
      </section>


      <section id="plans"  className="bg-primary">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Today Global Aamod Floor Plans </h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row no-gutter  wow fadeInUp">
               <div className="col-lg-10 col-sm-10 col-xs-12 col-md-offset-2">
				   <div className="col-md-4 col-xs-12">
						<h3 style={{textAlign:"center"}}>Layout Map</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals" >
						 <img src="img/floorplan/layout.jpg"  className="img-responsive" alt="Today Global Aamod" title="Today Global Aamod"/>
						  
					  </a>
				   </div>
				   <div className="col-md-2 col-xs-12">
				   </div>
				   <div className="col-md-4 col-xs-12">
					<h3 style={{textAlign:"center"}}>Floor Plans</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals" >
						 <img src="img/floorplan/floorplan-hinde.jpg" style={{width:"100%"}} className="img-responsive" alt="Today Global Aamod" title="Today Global Aamod"/>
						  
					  </a>
				   </div>
               </div>
               			   
			   
            </div>
         </div>
      </section>
    
      
      <section id="location">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Location</h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
				<div className="col-lg-7 col-sm-7 pop_align">
					<div className="col-lg-12 col-sm-12">	
						 <a href="#myModals">
						 <img src="img/location.jpg" alt="Location Today Global Aamod" title="Today Global Aamod" data-toggle="modal" data-target="#myModals" className="img-responsive pop_align" /><br/>
						 </a>
					</div>
			    </div> 
				<div className="col-sm-5">
					<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
						<div className="panel panel-default active">
							<div className="panel-heading" role="tab" id="headingOne">
								<h4 className="panel-title">
									<a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										CONNECTIVITY
									</a>
								</h4>
							</div>
							<div id="collapseOne" className="panel-collapse" role="tabpanel" aria-labelledby="headingOne">
								<div className="panel-body">
									  <ul>
										<li>Nearby Schools, Markets & Hospitals</li>
										<li>The 22KM Navi Mumbai TransHarbour Sea Link to Panvel is also expected to be ready by 2022.</li>
										<li>JNPT, India's biggest cargo terminal in Navi Mumbai is slated to be on par with European ports. </li>
										 
									  </ul>
								</div>
							</div>
						</div>

						
						
						</div>
				</div>

			   
            </div>
			
         </div>
      </section>

      <aside className="bg-dark">
         <div className="container text-center">
            <div className="call-to-action">
               <h3>For more Information call : +91 91305 68016</h3>
               <a href="#myModal" data-toggle="modal" data-target="#myModal" className="btn btn-default btn-xl sr-button">Download Brochure!</a>
            </div>
         </div>
      </aside>

      
	  
	 

 <section id="contact"  >
         <div className="container">
            <div className="row">
				<div className="col-lg-6 text-center">
                  <h3 className="section-heading">About Developer</h3>
                  <hr className="light"/>
					
					<p>Today Global Developers under the leadership of young, energetic & visionary Managing Director Mr. Bhadresh Shah began with a seed capital of only 50 Lakh, Today the group has reached to aturnover of 100 Crore plus in the short span of 13 years. Over these years, Today Global Group has emerged as a leading name in the Construction and Real EstateSector. With Lifestyle & Skyline of residential and commercial projects across Navi Mumbai, Today Global Group is fast touching the next level. With non-compromising attitude towards quality and delivering premium lifestyles or affordable luxury, Today Global Group projects exceed the expectations of its customers. Today Global Group has successfully delivered 20+projects, with 15lacs square feet under-construction feet as the company gears up to deliver 5000 homes over the next 7 years.
</p>
               </div>
			   
               <div className="col-lg-6 text-center">
                  <h3 className="section-heading">Lets Get In Touch! </h3>
                  <hr className="primary"/>
                  <form method="post" id="contactinq">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fname" placeholder="Name" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobile" placeholder="Mobile" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesg" placeholder="Massage" rows="4"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit"/>
                        </div>
                     </div>
                     <div className="success_message pop_align"></div>
                     <br/>
                  </form>
               </div>
               
			   <div className="col-lg-12 text-center">
                  <i className="fa fa-phone fa-3x sr-contact"></i>
                  <h3>+91 9130568016</h3>
               </div>
			   
			   
            </div>
         </div>
      </section>

      <div className="bg-dark">
         <div className="container text-center">
            <div>
               
			   <div className="reranumber"><b>Project - MahaRERA No.:</b> P52000030717</div>
               <div className="disclaimer"><b>Disclaimer:</b>  This Website Is Managed By Today Global Aamod Panvel, Navi Mumbai Authorised Media Partner.</div>
          <br/><br/>
            </div>
         </div>
      </div>

      <div className="whatsapp-area">
         <div className="chat-button-area zoomOut">
            <button type="button" id="whatsapp-btn"  className="whatsapp-btn"> 
            <a href="https://wa.me/919082164514?text=Hi.. I am interested in Today Global Aamod Project. Please help me with the details. Thanks!" target="_blank">
            <span className="icon-whatsapp"></span> <i className="fa fa-whatsapp"></i>
            </a>
            </button> 
            <div className="circle-animation">
            </div>
         </div>
      </div>
    
    </div>
  );
}

export default App;
